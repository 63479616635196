<template>
   <div class="admin-panel__login">
      <v-form class="admin-panel__login__form" @keydown.native.enter="submitLogin">
         <p class="admin-panel__login__wrong" v-if="isWrongCredentials">Wrong Credentials</p>
         <v-text-field
            class="admin-panel__login__email"
            v-model.trim="email"
            :rules="[rules.required, rules.emailMatch]"
            label="E-mail"
            hint="Email@gmail.com"
            @focus="isWrongCredentials = false"
         ></v-text-field>
         <v-text-field
            class="admin-panel__login__password"
            v-model.trim="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            hint="At least 8 characters"
            label="Password"
            @click:append="showPassword = !showPassword"
            @focus="isWrongCredentials = false"
         ></v-text-field>
         <v-btn class="admin-panel__login__btn" @click="submitLogin"> Login </v-btn>
      </v-form>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';

export default {
   name: 'AdminPanelLogin',
   data() {
      return {
         email: '',
         password: '',
         rules: {
            required: value => !!value || 'Required',
            min: value => value.length >= 4 || 'Min 8 characters',
            emailMatch: value => !!value.match(/.*@\w+\.\w{1,3}/g) || 'Email is not valid',
         },
         showPassword: false,
         isErrorAuth: false,
         isWrongCredentials: false,
      };
   },
   methods: {
      async submitLogin() {
         const validEmail = !!this.email.match(/.*@\w+\.\w{1,3}/g);
         const validPassword = this.password.length >= 4;
         if (validEmail && validPassword) {
            const postValue = {
               email: this.email,
               password: this.password,
            };
            try {
               const response = await this.$http.post('/auth/admin_login', postValue);
               //localStorage.removeItem('token');
               sessionStorage.token = response.data.token;
               sessionStorage.isAuth = true;
               //this.updateAdmin(response.data.admin);
               this.$store.commit(`${mutations.UPDATE_AUTH_ADMIN}`, true);
               this.$router.push({ name: 'admin-dashboard' });
            } catch (error) {
               this.isWrongCredentials = true;
            }
         }
      },
   },
};
</script>

<style lang="scss">
.admin-panel__login {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100vw;
   height: 100vh;
   &__form {
      padding: 40px;
      border: 1px solid var(--green-lighten-2);
      border-radius: 10px;
      background: var(--green-lighten-5);
      width: 400px !important;
      height: max-content !important;
   }
   &__email {
      margin-bottom: 20x;
   }
   &__password {
      margin-bottom: 20px;
   }

   &__wrong {
      color: darken($color: red, $amount: 8);
      font-weight: bold;
      font-size: 1.2rem;
      margin: 0;
      text-align: center;
   }

   &__btn {
      background: var(--green-lighten-3) !important;
      width: 100%;
   }
}
</style>